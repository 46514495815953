import axios from "axios";
import Api from "./Api";
import { app } from "@/main.js";

class MaximizerSchemaApi extends Api {
  constructor() {
    super("udf entry", "maximizer-schema");
  }
  async searchByName(nameData) {
    let result;
    // const errorText = "Failed Retrieving User Profile";
    const errorText = app.$i18n.t("errorMessage.maximizerSchemaApi.userProfile");

    try {
      let url = `/${this.endpoint}/search`;
      result = await axios.post(url, nameData);
      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
}

export default MaximizerSchemaApi;
